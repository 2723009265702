.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 45%;
  background:hsl(180, 100%, 90%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
