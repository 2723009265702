@import "../variables.css";

.button {
  font: inherit;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ff0055;
  border-radius: 4px;
  background: #ff0055;
  color: var(--color-text-light);
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 0.75rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #ff4382;
  border-color: #ff4382;
}

.button--inverse {
  background: rgba(255, 255, 255, 0.5);
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: var(--color-text-light);
  background: #ff0055;
}


.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

@media (min-width: 414px) {
  .button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
}
