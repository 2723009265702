@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #4d4d4d;
}

.slide-in-right-enter {
  transform: translateX(100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
:root {
  --color-base-hue: 180;
  --color-text-light: hsl(var(--color-base-hue), 100%, 87%);
}
.card {
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: linear-gradient(to right ,hsl(var(--color-base-hue), 60%, 95%),hsl(var(--color-base-hue), 60%, 90%));
}


.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  resize: vertical;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: rgb(255, 0, 0);
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.button {
  font: inherit;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ff0055;
  border-radius: 4px;
  background: #ff0055;
  color: var(--color-text-light);
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 0.75rem;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #ff4382;
  border-color: #ff4382;
}

.button--inverse {
  background: rgba(255, 255, 255, 0.5);
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: var(--color-text-light);
  background: #ff0055;
}


.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

@media (min-width: 414px) {
  .button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 15vh;
  left: 10%;
  width: 80%;
  max-height:-webkit-fit-content;
  max-height:-moz-fit-content;
  max-height:fit-content;
  background: linear-gradient(to right ,hsl(var(--color-base-hue), 60%, 95%),hsl(var(--color-base-hue), 60%, 90%));
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: linear-gradient(to right ,hsl(var(--color-base-hue), 100%, 10%),hsl(var(--color-base-hue), 100%, 16%));
  color: var(--color-text-light);
  font-size: 0.75rem;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

body.modal-enter-done {
  overflow: hidden;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 13rem;
  height: 13rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload__preview:hover {
  cursor: pointer;
}

.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to right ,hsl(var(--color-base-hue), 100%, 10%),hsl(var(--color-base-hue), 100%, 16%));
  color: var(--color-text-light);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #f8df00;
  border-color: #292929;
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: var(--color-text-light);
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid var(--color-text-light);
    color: var(--color-text-light);
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}

.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 45%;
  background:hsl(180, 100%, 90%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: var(--color-text-light);
}

.main-navigation__title {
  color: var(--color-text-light);
}

.main-navigation__title a {
  text-decoration: none;
  color: var(--color-text-light);
  font-size: 1.75rem;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

@media (min-width: 414px) {
  .main-navigation__title a {
    font-size: 2rem;
  }
}

